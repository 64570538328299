// False positive. Package can be resolved.
// eslint-disable-next-line import/no-unresolved
import { ATTR_SESSION_ID } from '@opentelemetry/semantic-conventions/incubating';
export class FaroSessionSpanProcessor {
  constructor(processor, metas) {
    this.processor = processor;
    this.metas = metas;
  }
  forceFlush() {
    return this.processor.forceFlush();
  }
  onStart(span, parentContext) {
    const session = this.metas.value.session;
    if (session === null || session === void 0 ? void 0 : session.id) {
      span.attributes[ATTR_SESSION_ID] = session.id;
      /**
       * @deprecated will be removed in the future and has been replaced by ATTR_SESSION_ID (session.id)
       */
      span.attributes['session_id'] = session.id;
    }
    this.processor.onStart(span, parentContext);
  }
  onEnd(span) {
    this.processor.onEnd(span);
  }
  shutdown() {
    return this.processor.shutdown();
  }
}
