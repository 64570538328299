import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { FoIconComponent } from '@ui/components/fo-icon/fo-icon.component';
import { Observable } from 'rxjs';
import { Auth2Service } from 'src/app/services/auth2.service';
import { selectNameAcronimn } from 'src/app/states/user/selector/user.selector';
import { UserState } from 'src/app/states/user/user.state';
import { environment } from 'src/environments/environment';
import { SlideToggleComponent } from './components/slide-toggle/slide-toggle.component';
import { ThemeService } from './services/theme.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    //
    FoIconComponent,
    //
    SlideToggleComponent,
  ],
  providers: [ThemeService],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  /**
   * Theme control
   */
  isDarkTheme: boolean;

  /**
   * User's name acronym
   */
  userAcronymn$: Observable<any>;

  constructor(
    private readonly authService: Auth2Service,
    private readonly themeService: ThemeService,
    private store: Store<UserState>,
  ) {}

  ngOnInit(): void {
    this.setInitialValues();
  }

  /**
   * Set initial actions
   */
  setInitialValues(): void {
    this.isDarkTheme = this.themeService.isDarkThemeSaved();
    this.userAcronymn$ = this.store.select(selectNameAcronimn);
  }

  /**
   * Action on theme change
   * @param isLightTheme if selected theme is light
   */
  onThemeChange(isLightTheme: boolean) {
    this.themeService.updateTheme(!isLightTheme);
  }

  /**
   * Sing out user
   */
  singout(): void {
    this.authService.logout$.next();
  }

  /**
   * Is dev env
   */
  isDevEnvironment(): boolean {
    return !environment.production;
  }
}
