import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { StatusBadgeComponent } from '@ui/components/status-badge/status-badge.component';
import { BreadcrumbState } from 'src/app/states/breadcrumb/breadcrumb.state';
import { selectBreadcrumbState } from 'src/app/states/breadcrumb/selector/breadcrumb.selector';

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    // Commons
    StatusBadgeComponent,
  ],
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbComponent implements OnInit {
  breadcrumb: BreadcrumbState;

  constructor(private store: Store<BreadcrumbState>) {}

  ngOnInit(): void {
    this.store
      .select(selectBreadcrumbState)
      .subscribe((breadcrumb) => (this.breadcrumb = breadcrumb));
  }

  /**
   *
   * @param index
   * @param label
   * @returns
   */
  getName(index: number, label: string): string {
    const partsQty = this.breadcrumb.parts.length;
    return index !== partsQty - 1 && partsQty > 1 ? `${label} /` : label;
  }
}
