<section class="breadcrumb-component" [ngClass]="{ 'breadcrumb--sticky': breadcrumb.sticky }">
  <div class="breadcrumb__margin-top"></div>
  <nav class="nav-breadcrumb">
    <ng-container
      *ngFor="let part of breadcrumb.parts; let index = index; let first = first; let last = last">
      <ng-container *ngIf="!last; else isLast">
        <a [routerLink]="part.route">
          <h1 *ngIf="first; else common">{{getName(index, part.name)}}</h1>
          <ng-template #common>{{getName(index, part.name)}}</ng-template>
        </a>
      </ng-container>
      <ng-template #isLast>
        <span [ngClass]="{ 'one-element': breadcrumb.parts.length === 1 }">
          {{getName(index, part.name)}}
        </span>
        <ng-container *ngIf="breadcrumb.details; let details">
          - 
          <fo-status-badge [status]="details.status"></fo-status-badge>
          <span *ngIf="details.description"> - {{details.description}}</span>
        </ng-container>
      </ng-template>
    </ng-container>
  </nav>
</section>
