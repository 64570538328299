var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
import { BaseTransport, createPromiseBuffer, getTransportBody, noop, VERSION } from '@grafana/faro-core';
import { getSessionManagerByConfig } from '../../instrumentations/session/sessionManager';
import { getUserSessionUpdater } from '../../instrumentations/session/sessionManager/sessionManagerUtils';
const DEFAULT_BUFFER_SIZE = 30;
const DEFAULT_CONCURRENCY = 5; // chrome supports 10 total, firefox 17
const DEFAULT_RATE_LIMIT_BACKOFF_MS = 5000;
const BEACON_BODY_SIZE_LIMIT = 60000;
const TOO_MANY_REQUESTS = 429;
const ACCEPTED = 202;
export class FetchTransport extends BaseTransport {
  constructor(options) {
    var _a, _b, _c, _d;
    super();
    this.options = options;
    this.name = '@grafana/faro-web-sdk:transport-fetch';
    this.version = VERSION;
    this.disabledUntil = new Date();
    this.rateLimitBackoffMs = (_a = options.defaultRateLimitBackoffMs) !== null && _a !== void 0 ? _a : DEFAULT_RATE_LIMIT_BACKOFF_MS;
    this.getNow = (_b = options.getNow) !== null && _b !== void 0 ? _b : () => Date.now();
    this.promiseBuffer = createPromiseBuffer({
      size: (_c = options.bufferSize) !== null && _c !== void 0 ? _c : DEFAULT_BUFFER_SIZE,
      concurrency: (_d = options.concurrency) !== null && _d !== void 0 ? _d : DEFAULT_CONCURRENCY
    });
  }
  send(items) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        if (this.disabledUntil > new Date(this.getNow())) {
          this.logWarn(`Dropping transport item due to too many requests. Backoff until ${this.disabledUntil}`);
          return Promise.resolve();
        }
        yield this.promiseBuffer.add(() => {
          const body = JSON.stringify(getTransportBody(items));
          const {
            url,
            requestOptions,
            apiKey
          } = this.options;
          const _a = requestOptions !== null && requestOptions !== void 0 ? requestOptions : {},
            {
              headers
            } = _a,
            restOfRequestOptions = __rest(_a, ["headers"]);
          let sessionId;
          const sessionMeta = this.metas.value.session;
          if (sessionMeta != null) {
            sessionId = sessionMeta.id;
          }
          return fetch(url, Object.assign({
            method: 'POST',
            headers: Object.assign(Object.assign(Object.assign({
              'Content-Type': 'application/json'
            }, headers !== null && headers !== void 0 ? headers : {}), apiKey ? {
              'x-api-key': apiKey
            } : {}), sessionId ? {
              'x-faro-session-id': sessionId
            } : {}),
            body,
            keepalive: body.length <= BEACON_BODY_SIZE_LIMIT
          }, restOfRequestOptions !== null && restOfRequestOptions !== void 0 ? restOfRequestOptions : {})).then(response => __awaiter(this, void 0, void 0, function* () {
            if (response.status === ACCEPTED) {
              const sessionExpired = response.headers.get('X-Faro-Session-Status') === 'invalid';
              if (sessionExpired) {
                this.extendFaroSession(this.config, this.logDebug);
              }
            }
            if (response.status === TOO_MANY_REQUESTS) {
              this.disabledUntil = this.getRetryAfterDate(response);
              this.logWarn(`Too many requests, backing off until ${this.disabledUntil}`);
            }
            // read the body so the connection can be closed
            response.text().catch(noop);
            return response;
          })).catch(err => {
            this.logError('Failed sending payload to the receiver\n', JSON.parse(body), err);
          });
        });
      } catch (err) {
        this.logError(err);
      }
    });
  }
  getIgnoreUrls() {
    var _a;
    return [this.options.url].concat((_a = this.config.ignoreUrls) !== null && _a !== void 0 ? _a : []);
  }
  isBatched() {
    return true;
  }
  getRetryAfterDate(response) {
    const now = this.getNow();
    const retryAfterHeader = response.headers.get('Retry-After');
    if (retryAfterHeader) {
      const delay = Number(retryAfterHeader);
      if (!isNaN(delay)) {
        return new Date(delay * 1000 + now);
      }
      const date = Date.parse(retryAfterHeader);
      if (!isNaN(date)) {
        return new Date(date);
      }
    }
    return new Date(now + this.rateLimitBackoffMs);
  }
  extendFaroSession(config, logDebug) {
    const SessionExpiredString = `Session expired`;
    const sessionTrackingConfig = config.sessionTracking;
    if (sessionTrackingConfig === null || sessionTrackingConfig === void 0 ? void 0 : sessionTrackingConfig.enabled) {
      const {
        fetchUserSession,
        storeUserSession
      } = getSessionManagerByConfig(sessionTrackingConfig);
      getUserSessionUpdater({
        fetchUserSession,
        storeUserSession
      })({
        forceSessionExtend: true
      });
      logDebug(`${SessionExpiredString} created new session.`);
    } else {
      logDebug(`${SessionExpiredString}.`);
    }
  }
}
