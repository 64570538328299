import { TransportItemType } from '../../transports';
import { deepEqual, defaultLogLevel, getCurrentTimestamp, isNull } from '../../utils';
import { timestampToIsoString } from '../../utils/date';
import { defaultLogArgsSerializer } from './const';
export function initializeLogsAPI(_unpatchedConsole, internalLogger, config, metas, transports, tracesApi) {
  var _a;
  internalLogger.debug('Initializing logs API');
  let lastPayload = null;
  const logArgsSerializer = (_a = config.logArgsSerializer) !== null && _a !== void 0 ? _a : defaultLogArgsSerializer;
  const pushLog = (args, {
    context,
    level,
    skipDedupe,
    spanContext,
    timestampOverwriteMs
  } = {}) => {
    try {
      const item = {
        type: TransportItemType.LOG,
        payload: {
          message: logArgsSerializer(args),
          level: level !== null && level !== void 0 ? level : defaultLogLevel,
          context: context !== null && context !== void 0 ? context : {},
          timestamp: timestampOverwriteMs ? timestampToIsoString(timestampOverwriteMs) : getCurrentTimestamp(),
          trace: spanContext ? {
            trace_id: spanContext.traceId,
            span_id: spanContext.spanId
          } : tracesApi.getTraceContext()
        },
        meta: metas.value
      };
      const testingPayload = {
        message: item.payload.message,
        level: item.payload.level,
        context: item.payload.context
      };
      if (!skipDedupe && config.dedupe && !isNull(lastPayload) && deepEqual(testingPayload, lastPayload)) {
        internalLogger.debug('Skipping log push because it is the same as the last one\n', item.payload);
        return;
      }
      lastPayload = testingPayload;
      internalLogger.debug('Pushing log\n', item);
      transports.execute(item);
    } catch (err) {
      internalLogger.error('Error pushing log\n', err);
    }
  };
  return {
    pushLog
  };
}
