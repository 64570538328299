import { ErrorHandler, Injectable } from '@angular/core';
import { SessionStorageKeysEnum } from 'src/app/enums/local-storage-keys.enum';

@Injectable()
export class GlobalErrorHandlerService extends ErrorHandler {
  private readonly lastRefreshDelta: number = 15000;

  constructor() {
    super();
  }

  public handleError(error: unknown | null): void {
    if (this.handleChunkLoadError(error)) {
      return;
    }
    super.handleError(error);
  }

  private handleChunkLoadError(error: any | null): boolean {
    const isChunkLoadError: boolean = !!error && error.message.includes('ChunkLoadError');
    if (isChunkLoadError) {
      const lastRefreshTime: number =
        Number(sessionStorage.getItem(SessionStorageKeysEnum.ChunkErrorLastRefresh)) || 0;
      const currentTime: number = new Date().getTime();
      const lastRefreshDelta: number = Math.abs(currentTime - lastRefreshTime);
      if (lastRefreshDelta > this.lastRefreshDelta) {
        window.location.reload();
        sessionStorage.setItem(
          SessionStorageKeysEnum.ChunkErrorLastRefresh,
          currentTime.toString(),
        );
        return true;
      }
    }
    return false;
  }
}
