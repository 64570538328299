import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { updateUserThemePreference } from 'src/app/states/user/actions/user.actions';
import { UserState } from 'src/app/states/user/user.state';

enum PREFERNECES_KEY {
  ColorScheme = 'COLOR_SCHEME',
}

enum THEME {
  dark = 0,
  light = 1,
}

@Injectable()
export class ThemeService {
  /**
   * Classes by theme
   */
  static readonly THEME_CLASS: Record<THEME, string> = {
    [THEME.dark]: 'dark-theme',
    [THEME.light]: 'light-theme',
  };

  /**
   * Light as default theme
   */
  private theme: THEME = THEME.light;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<UserState>,
  ) {}

  /**
   * Get if dark theme
   * @param value 0 for dark, 1 for light
   * @returns if the number matches with dark value
   */
  private isDarkTheme(value: number): boolean {
    return value === THEME.dark;
  }

  /**
   * Determine if current prefered color scheme is dark
   * @returns if the number matches with dark value
   */
  private isSystemDarkTheme(): boolean {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  /**
   * Get the theme saved on local storage
   * @returns the theme on local storage, null otherwise
   */
  private isLocalStorageDarkTheme(): boolean | null {
    const theme = localStorage.getItem(PREFERNECES_KEY.ColorScheme);
    if (!theme) return null;
    return this.isDarkTheme(parseInt(theme));
  }

  /**
   * Update theme on local storage and ngrx user preferences
   */
  updateTheme(isDarkTheme: boolean) {
    this.theme = isDarkTheme ? THEME.dark : THEME.light;
    this.store.dispatch(updateUserThemePreference({ isDarkTheme }));
    localStorage.setItem(PREFERNECES_KEY.ColorScheme, this.theme.toString());
    // Update clases
    this.switchThemeClass(this.theme);
  }

  /**
   * Get is there is any preference stored related to darktheme
   * @returns if darktheme is a user preference
   */
  isDarkThemeSaved(): boolean {
    const isDarkTheme =
      this.isLocalStorageDarkTheme() === null
        ? this.isSystemDarkTheme()
        : this.isLocalStorageDarkTheme();
    this.updateTheme(isDarkTheme);
    return isDarkTheme;
  }

  /**
   * Change the theme for the available items
   * @param previous previous theme
   * @param current new theme
   */
  switchThemeClass(current: THEME) {
    this.document.body.classList.remove(...Object.values(ThemeService.THEME_CLASS));
    this.document.body.classList.add(ThemeService.THEME_CLASS[current]);
  }
}
