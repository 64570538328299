import { TransportItemType } from '../../transports';
import { deepEqual, getCurrentTimestamp, isNull } from '../../utils';
import { timestampToIsoString } from '../../utils/date';
export function initializeMeasurementsAPI(_unpatchedConsole, internalLogger, config, metas, transports, tracesApi) {
  internalLogger.debug('Initializing measurements API');
  let lastPayload = null;
  const pushMeasurement = (payload, {
    skipDedupe,
    context,
    spanContext,
    timestampOverwriteMs
  } = {}) => {
    try {
      const item = {
        type: TransportItemType.MEASUREMENT,
        payload: Object.assign(Object.assign({}, payload), {
          trace: spanContext ? {
            trace_id: spanContext.traceId,
            span_id: spanContext.spanId
          } : tracesApi.getTraceContext(),
          timestamp: timestampOverwriteMs ? timestampToIsoString(timestampOverwriteMs) : getCurrentTimestamp(),
          context: context !== null && context !== void 0 ? context : {}
        }),
        meta: metas.value
      };
      const testingPayload = {
        type: item.payload.type,
        values: item.payload.values,
        context: item.payload.context
      };
      if (!skipDedupe && config.dedupe && !isNull(lastPayload) && deepEqual(testingPayload, lastPayload)) {
        internalLogger.debug('Skipping measurement push because it is the same as the last one\n', item.payload);
        return;
      }
      lastPayload = testingPayload;
      internalLogger.debug('Pushing measurement\n', item);
      transports.execute(item);
    } catch (err) {
      internalLogger.error('Error pushing measurement\n', err);
    }
  };
  return {
    pushMeasurement
  };
}
