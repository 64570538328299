import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { FoAlertComponent } from '../fo-alert/fo-alert.component';
import { FoToastNotificationComponent } from './fo-toast-notification.component';

@NgModule({
  declarations: [FoToastNotificationComponent],
  imports: [
    CommonModule,
    RouterModule,
    // Commons
    FoAlertComponent,
  ],
  exports: [FoToastNotificationComponent],
  providers: [AlertService],
})
export class FoToastNotificationModule {}
