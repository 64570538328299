<header class="header">
  <img src="assets/img/logo-fundo.png">

	<div class="header__actions">
		<app-slide-toggle leftOption="DARK" rightOption="LIGHT" [value]="!isDarkTheme" (valueChange)="onThemeChange($event)"></app-slide-toggle>
		<div class="header__avatar">
			<span>
				{{ userAcronymn$ | async }}
			</span>
		</div>
		<div class="header__singout" (click)="singout()">
			<span>Sign OUT</span>
			<fo-icon name="chevron-right"></fo-icon>
		</div> 
	</div>
</header>
