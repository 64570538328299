import { FlatTreeControl } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { FoIconComponent } from '@ui/components/fo-icon/fo-icon.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Auth2Service, Item } from 'src/app/services/auth2.service';

/** Flat node with expandable and level information */
interface FlatNode extends Item {
  expandable: boolean;
  level: number;
}

@Component({
  selector: 'app-main-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    // Material
    MatTooltipModule,
    MatTreeModule,
    // Commons
    FoIconComponent,
  ],
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MainMenuComponent implements OnInit, OnDestroy {
  /**
   * On destroy indicator
   */
  private destroy$: Subject<void> = new Subject();

  private _transformer = (node: Item, level: number): FlatNode => {
    return {
      ...node,
      expandable: !!node.subItems && node.subItems.length > 0,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.subItems,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  @Input()
  isExpanded: boolean;

  constructor(private readonly authService: Auth2Service) {}

  ngOnInit(): void {
    this.authService
      .getMenu()
      .pipe(takeUntil(this.destroy$))
      .subscribe((items) => (this.dataSource.data = items));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;
}
