import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { AdminLogOutCountdownComponent } from '@ui/dialogs/admin-log-out-countdown/admin-log-out-countdown.component';
import {
  MINUTES_UNTIL_AUTO_LOGOUT,
  SECONDS_UNTIL_WARNING_LOGOUT,
} from 'src/app/constants/logout-times';
import { STORAGE_COUNTER_KEY } from 'src/app/constants/storage-counter';
import { Auth2Service } from './auth2.service';

@Injectable()
export class AutoLogOutService {
  /**
   *
   */
  private dialogRef: MatDialogRef<AdminLogOutCountdownComponent>;

  public getLastAction(): number {
    return parseInt(localStorage.getItem(STORAGE_COUNTER_KEY));
  }

  public setLastAction(lastAction: number): void {
    if (this.authService.isValidSession()) {
      localStorage.setItem(STORAGE_COUNTER_KEY, lastAction.toString());
    }
  }

  constructor(
    private readonly authService: Auth2Service,
    private readonly dialog: MatDialog,
  ) {}

  initCountdown(): void {
    this.reset();
    this.check();
    this.initListener();
    this.initInterval();
  }

  public initListener(): void {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover', () => this.reset());
    document.body.addEventListener('mouseout', () => this.reset());
    document.body.addEventListener('keydown', () => this.reset());
    document.body.addEventListener('keyup', () => this.reset());
    document.body.addEventListener('keypress', () => this.reset());
  }

  private reset(): void {
    this.setLastAction(Date.now());
  }

  private initInterval(): void {
    setInterval(() => {
      this.check();
    }, 5000);
  }

  private check(): void {
    if (this.authService.isSessionPresent()) {
      const now: number = Date.now();
      const timeLeft: number = this.getLastAction() + MINUTES_UNTIL_AUTO_LOGOUT * 60 * 1000;
      const diff: number = timeLeft - now;
      const isShowWarning = diff < SECONDS_UNTIL_WARNING_LOGOUT * 1000;
      const isTimeout: boolean = !diff || diff <= 0;

      // Logout from all the open sessions
      if (!this.dialogRef) {
        if (isTimeout) {
          this.authService.logout$.next();
          return;
        }

        if (isShowWarning) {
          this.dialogRef = this.dialog.open(AdminLogOutCountdownComponent, {
            width: '500px',
            disableClose: true,
          });
          this.dialogRef.afterClosed().subscribe(() => (this.dialogRef = null));
        }
      }
    }
  }
}
