import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';

import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { MAT_LEGACY_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/legacy-paginator';
import { MatLegacySnackBarModule } from '@angular/material/legacy-snack-bar';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideStore } from '@ngrx/store';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { routes } from './app.routes';
import { AreUnsavedChangesGuard } from './guards/are-unsaved-changes.guard';
import { Auth2Guard } from './guards/auth2.guard';
import { RemoveNullParamsInterceptor } from './interceptors/http-parameters.interceptor';
import { Token2Interceptor } from './interceptors/token2.interceptor';
import { ApiBaseService } from './services/api-base.service';
import { AutoLogOutService } from './services/auto-log-out.service';
import { EnvironmentService } from './services/environment.service';
import { GlobalErrorHandlerService } from './services/global-error-handler.service';
import { SnackBarService } from './services/snack-bar.service';
import { StateManagerService } from './services/state-manager.service';
import { breadcrumbReducer } from './states/breadcrumb/reducer/breadcrumb.reducer';
import { userReducer } from './states/user/reducer/user.reducer';

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'top',
      }),
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
    ),
    provideAnimations(),
    // Shared
    provideEnvironmentNgxMask(maskConfigFunction),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    // ngrx
    provideStore({ user: userReducer, breadcrumb: breadcrumbReducer }),
    // Customs
    AreUnsavedChangesGuard,
    Auth2Guard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Token2Interceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RemoveNullParamsInterceptor,
      multi: true,
    },
    {
      provide: MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        hideRequiredMarker: true,
      },
    },
    {
      provide: MAT_LEGACY_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        formFieldAppearance: 'standard',
      },
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    // Modules
    importProvidersFrom(MatLegacyDialogModule),
    importProvidersFrom(MatLegacySnackBarModule),
    // Global Services
    ApiBaseService,
    AutoLogOutService,
    EnvironmentService,
    SnackBarService,
    StateManagerService,
  ],
};
