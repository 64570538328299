<mat-sidenav-container class="sidenav-container">
  <!-- SIDENAV -->
  <mat-sidenav
    #sidenav
    class="sidenav"
    mode="side"
    opened="true"
    [ngStyle]="{'width': isExpanded ? SIDENAV_WIDTH.expanded: SIDENAV_WIDTH.collapsed}"
    [ngClass]="{'collapsed' : !isExpanded}">
    <!-- EXPAND/CLOSE BUTTON -->
    <button mat-icon-button class="sidenav__button" (click)="isExpanded = !isExpanded">
      <fo-icon [name]="isExpanded ? 'chevron-left' : 'chevron-right'"></fo-icon>
    </button>
    <!-- MENU -->
    <app-main-menu [isExpanded]="isExpanded"></app-main-menu>
  </mat-sidenav>

  <!-- CONTENT -->
  <mat-sidenav-content
    class="page-content"
    [ngStyle]="{'margin-left': isExpanded ? SIDENAV_WIDTH.expanded: SIDENAV_WIDTH.collapsed}">
    <!-- HEADER -->
    <app-header></app-header>
    <app-breadcrumb></app-breadcrumb>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
