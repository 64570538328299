import { inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SVG_CONFIG } from './constants/svg-icons';

type SVGIconRegisterDeps = [MatIconRegistry, DomSanitizer];

export class SVGIconRegister {
  /**
   * Register all icons defined in `svg-icons.ts`.
   */
  static registerIcons(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer): void {
    Object.keys(SVG_CONFIG.icons).forEach((icon: string) => {
      iconRegistry.addSvgIcon(
        icon,
        sanitizer.bypassSecurityTrustResourceUrl(
          `${SVG_CONFIG.folder}/${SVG_CONFIG.icons[icon]}.svg`,
        ),
      );
    });
  }

  /**
   * Inject dependencies
   * @returns
   */
  static deps(): SVGIconRegisterDeps {
    const iconRegistry = inject(MatIconRegistry);
    const sanitizer = inject(DomSanitizer);
    return [iconRegistry, sanitizer];
  }
}
