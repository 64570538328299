import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SVGIconRegister } from '@ui/components/fo-icon/svg-icon-register';
import { FoToastNotificationModule } from '@ui/components/fo-toast-notification/fo-toast-notification.module';
import { ThemeService } from '@ui/layout/header/services/theme.service';
import { AutoLogOutService } from 'src/app/services/auto-log-out.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, FoToastNotificationModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [AutoLogOutService, ThemeService],
})
export class AppComponent {
  constructor(
    private readonly autologoutService: AutoLogOutService,
    private readonly themeService: ThemeService,
  ) {
    this.autologoutService.initCountdown();
    this.themeService.isDarkThemeSaved();

    // Register SVG icons
    SVGIconRegister.registerIcons(...SVGIconRegister.deps());
  }
}
