import { createInternalLogger, defaultBatchingConfig, defaultGlobalObjectKey, defaultInternalLoggerLevel, defaultLogArgsSerializer, defaultUnpatchedConsole, isObject } from '@grafana/faro-core';
import { defaultEventDomain } from '../consts';
import { parseStacktrace } from '../instrumentations';
import { defaultSessionTrackingConfig } from '../instrumentations/session';
import { defaultMetas } from '../metas';
import { k6Meta } from '../metas/k6';
import { FetchTransport } from '../transports';
import { getWebInstrumentations } from './getWebInstrumentations';
export function makeCoreConfig(browserConfig) {
  var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
  const transports = [];
  const internalLogger = createInternalLogger(browserConfig.unpatchedConsole, browserConfig.internalLoggerLevel);
  if (browserConfig.transports) {
    if (browserConfig.url || browserConfig.apiKey) {
      internalLogger.error('if "transports" is defined, "url" and "apiKey" should not be defined');
    }
    transports.push(...browserConfig.transports);
  } else if (browserConfig.url) {
    transports.push(new FetchTransport({
      url: browserConfig.url,
      apiKey: browserConfig.apiKey
    }));
  } else {
    internalLogger.error('either "url" or "transports" must be defined');
  }
  function createMetas() {
    const initialMetas = defaultMetas;
    if (browserConfig.metas) {
      initialMetas.push(...browserConfig.metas);
    }
    const isK6BrowserSession = isObject(window.k6);
    if (isK6BrowserSession) {
      return [...initialMetas, k6Meta];
    }
    return initialMetas;
  }
  const config = {
    app: browserConfig.app,
    batching: Object.assign(Object.assign({}, defaultBatchingConfig), browserConfig.batching),
    dedupe: (_a = browserConfig.dedupe) !== null && _a !== void 0 ? _a : true,
    globalObjectKey: browserConfig.globalObjectKey || defaultGlobalObjectKey,
    instrumentations: (_b = browserConfig.instrumentations) !== null && _b !== void 0 ? _b : getWebInstrumentations(),
    internalLoggerLevel: (_c = browserConfig.internalLoggerLevel) !== null && _c !== void 0 ? _c : defaultInternalLoggerLevel,
    isolate: (_d = browserConfig.isolate) !== null && _d !== void 0 ? _d : false,
    logArgsSerializer: (_e = browserConfig.logArgsSerializer) !== null && _e !== void 0 ? _e : defaultLogArgsSerializer,
    metas: createMetas(),
    parseStacktrace,
    paused: (_f = browserConfig.paused) !== null && _f !== void 0 ? _f : false,
    preventGlobalExposure: (_g = browserConfig.preventGlobalExposure) !== null && _g !== void 0 ? _g : false,
    transports,
    unpatchedConsole: (_h = browserConfig.unpatchedConsole) !== null && _h !== void 0 ? _h : defaultUnpatchedConsole,
    beforeSend: browserConfig.beforeSend,
    eventDomain: (_j = browserConfig.eventDomain) !== null && _j !== void 0 ? _j : defaultEventDomain,
    ignoreErrors: browserConfig.ignoreErrors,
    // ignore cloud collector urls by default. These are URLs ending with /collect or /collect/ followed by alphanumeric characters.
    ignoreUrls: ((_k = browserConfig.ignoreUrls) !== null && _k !== void 0 ? _k : []).concat([/\/collect(?:\/[\w]*)?$/]),
    sessionTracking: Object.assign(Object.assign({}, defaultSessionTrackingConfig), browserConfig.sessionTracking),
    user: browserConfig.user,
    view: browserConfig.view,
    trackResources: browserConfig.trackResources,
    trackWebVitalsAttribution: browserConfig.trackWebVitalsAttribution
  };
  return config;
}
