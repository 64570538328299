import { NavigationEnd } from '@angular/router';
import { ResourcePage } from 'src/app/enums/auth2/resources';
import { BreadcrumbPart } from '../breadcrumb.state';

export class Breadcrumb {
  /**
   *
   * @param event
   * @returns
   */
  static getParts = (event: NavigationEnd): BreadcrumbPart[] => {
    const redirectParts = event?.urlAfterRedirects.split('/');

    return redirectParts
      .filter((part) => !!part)
      .map((part) => {
        const name = this.getName(part);
        let route: string;

        // Check if ID
        const isPartId = !!part.match(/\d+/g);
        if (isPartId) {
          const index = redirectParts.findIndex((p) => p === name);
          route = redirectParts
            .splice(0, index + 1)
            .filter((p) => p)
            .join('/');
        }

        const resource = ResourcePage.getResourceRelatedTo(`/${part}`);
        return resource ? { name: resource.name, route: resource.route } : { name, route };
      });
  };

  /**
   *
   * @param parts
   * @param name
   * @returns
   */
  static setLastPart = (
    original: BreadcrumbPart[] = [],
    name?: string,
    route: string = null,
  ): BreadcrumbPart[] => {
    // Original array cannot be modified
    const parts = [...original];
    if (parts.length <= 1 || !name) {
      return parts;
    }
    // If remove and replace last element
    parts.pop();
    parts.push({ name, route });
    return parts;
  };

  static setSpecificPart = (
    original: BreadcrumbPart[] = [],
    index: number,
    name: string,
    route: string = null,
  ): BreadcrumbPart[] => {
    // Original array cannot be modified
    const parts = [...original];
    const calcRoute = route || original[index].route;

    if (parts.length <= 1 || !name) {
      return parts;
    }

    parts[index] = { name, route: calcRoute };
    return parts;
  };

  static getName(part: string) {
    return part
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}
