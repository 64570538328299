import { createReducer, on } from '@ngrx/store';
import {
  clearUser,
  setUser,
  updateUserDetails,
  updateUserThemePreference,
} from '../actions/user.actions';
import { UserState } from '../user.state';

export const initialState: UserState = {
  firstName: null,
  lastName: null,
  email: null,
  role: null,
  permissions: null,
  preferences: null,
};

export const userReducer = createReducer(
  initialState,
  on(setUser, (state, user): UserState => user),
  on(updateUserDetails, (state, details): UserState => ({ ...state, ...details })),
  on(
    updateUserThemePreference,
    (state, { isDarkTheme }): UserState => ({
      ...state,
      preferences: { ...state.preferences, isDarkTheme },
    }),
  ),
  on(clearUser, (state): UserState => initialState),
);
