import { faro, genShortID } from '@grafana/faro-core';
import { RESOURCE_ENTRY } from './performanceConstants';
import { createFaroResourceTiming, entryUrlIsIgnored, getSpanContextFromServerTiming, includePerformanceEntry } from './performanceUtils';
const DEFAULT_TRACK_RESOURCES = {
  initiatorType: ['xmlhttprequest', 'fetch']
};
export function observeResourceTimings(faroNavigationId, pushEvent, ignoredUrls) {
  const trackResources = faro.config.trackResources;
  const observer = new PerformanceObserver(observedEntries => {
    const entries = observedEntries.getEntries();
    for (const resourceEntryRaw of entries) {
      if (entryUrlIsIgnored(ignoredUrls, resourceEntryRaw.name)) {
        return;
      }
      const resourceEntryJson = resourceEntryRaw.toJSON();
      let spanContext = getSpanContextFromServerTiming(resourceEntryJson === null || resourceEntryJson === void 0 ? void 0 : resourceEntryJson.serverTiming);
      if (trackResources == null && includePerformanceEntry(resourceEntryJson, DEFAULT_TRACK_RESOURCES) || trackResources) {
        const faroResourceEntry = Object.assign(Object.assign({}, createFaroResourceTiming(resourceEntryJson)), {
          faroNavigationId,
          faroResourceId: genShortID()
        });
        pushEvent('faro.performance.resource', faroResourceEntry, undefined, {
          spanContext,
          timestampOverwriteMs: performance.timeOrigin + resourceEntryJson.startTime
        });
      }
    }
  });
  observer.observe({
    type: RESOURCE_ENTRY,
    buffered: true
  });
}
