import { Routes } from '@angular/router';
import { DefaultTemplateComponent } from '@ui/templates/default-template/default-template.component';
import { Auth2Guard } from './guards/auth2.guard';

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login-page/routes').then((mod) => mod.LOGIN_PAGE_ROUTES),
  },
  {
    path: '',
    component: DefaultTemplateComponent,
    children: [
      {
        path: '',
        redirectTo: 'advances',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        canActivateChild: [Auth2Guard],
        loadChildren: () =>
          import('./pages/dashboard-page/routes').then((mod) => mod.DASHBOARD_PAGE_ROUTES),
      },
      {
        path: 'clients',
        canActivateChild: [Auth2Guard],
        loadChildren: () =>
          import('./pages/clients-page/routes').then((mod) => mod.CLIENTS_PAGE_ROUTES),
      },
      {
        path: 'advances',
        canActivateChild: [Auth2Guard],
        loadChildren: () =>
          import('./pages/advances-page/routes').then((mod) => mod.ADVANCES_PAGE_ROUTES),
      },
      {
        path: 'keywords',
        canActivateChild: [Auth2Guard],
        loadChildren: () =>
          import('./pages/keywords-page/routes').then((mod) => mod.KEYWORDS_PAGE_ROUTES),
      },
      {
        path: 'balances',
        canActivateChild: [Auth2Guard],
        loadChildren: () =>
          import('./pages/balances-page/routes').then((mod) => mod.BALANCES_PAGE_ROUTES),
      },
      {
        path: 'trusted-banks',
        canActivateChild: [Auth2Guard],
        loadChildren: () =>
          import('./pages/trusted-banks/routes').then((mod) => mod.TRUSTED_BANKS_PAGE_ROUTES),
      },
      {
        path: 'accepted-employers',
        canActivateChild: [Auth2Guard],
        loadChildren: () =>
          import('./pages/accepted-employers-page/routes').then(
            (mod) => mod.ACCEPTED_EMPLOYERS_PAGE_ROUTES,
          ),
      },
      {
        path: 'programs',
        canActivateChild: [Auth2Guard],
        loadChildren: () =>
          import('./pages/programs-page/routes').then((mod) => mod.PROGRAMS_PAGE_ROUTES),
      },
      {
        path: 'duplication-rules',
        canActivateChild: [Auth2Guard],
        loadChildren: () =>
          import('./pages/rules-page/routes').then((mod) => mod.RULES_PAGE_ROUTES),
      },
      {
        path: 'lead-providers',
        canActivateChild: [Auth2Guard],
        loadChildren: () =>
          import('./pages/lead-providers-page/routes').then(
            (mod) => mod.LEAD_PROVIDERS_PAGE_ROUTES,
          ),
      },
      {
        path: 'rules-management',
        canActivateChild: [Auth2Guard],
        loadChildren: () =>
          import('./pages/rules-management-page/routes').then(
            (mod) => mod.RULE_MANAGEMENT_PAGE_ROUTES,
          ),
      },
      {
        path: 'scorecards-upload',
        canActivateChild: [Auth2Guard],
        loadChildren: () =>
          import('./pages/scorecards-upload-page/routes').then((mod) => mod.SCORECARDS_PAGE_ROUTES),
      },
      {
        path: 'component-styles',
        canActivateChild: [Auth2Guard],
        loadChildren: () =>
          import('./pages/component-styles/routes').then((mod) => mod.COMPONENT_STYLE_PAGE_ROUTES),
      },
      // {
      // 	path: 'workflow-settings',
      // 	loadChildren: () =>
      // 		import('./pages/workflow-settings-page/routes')
      // 			.then(mod => mod.WORKFLOW_SETTINGS_PAGE_ROUTES),
      // },
      {
        path: 'not-authorized',
        canActivateChild: [Auth2Guard],
        loadComponent: () =>
          import('./pages/not-authorized-page/not-authorized-page.component').then(
            (mod) => mod.NotAuthorizedPageComponent,
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
