<mat-tree
  class="menu"
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  [ngClass]="{'menu--expanded': isExpanded}">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node class="menu__item" *matTreeNodeDef="let node" matTreeNodePadding>
    <!-- use a disabled button to provide padding for tree leaf -->
    <a
      routerLinkActive="active"
      [routerLink]="node.route"
      matTooltipPosition="right"
      matTooltipClass="mat-tooltip--no-tip"
      [matTooltip]="!isExpanded ? node.name : null">
      <fo-icon *ngIf="node.icon" [name]="node.icon"></fo-icon>
      <span>{{node.name}}</span>
    </a>
  </mat-tree-node>
  <!-- This is the tree node template for expandable items -->
  <mat-tree-node
    class="menu__item menu__item--expandible"
    *matTreeNodeDef="let node; when: hasChild"
    matTreeNodePadding>
    <div
      matTreeNodeToggle
      [attr.aria-label]="'Toggle ' + node.name"
      matTooltipPosition="right"
      [matTooltip]="!isExpanded ? node.name : null">
      <fo-icon *ngIf="node.icon" [name]="node.icon"></fo-icon>
      <span> {{node.name}} </span>
      <fo-icon [name]="treeControl.isExpanded(node) ? 'chevron-up' : 'chevron-down'"></fo-icon>
    </div>
  </mat-tree-node>
</mat-tree>
