<div class="custom-checkbox">
  <input
    id="status"
    name="status"
    type="checkbox"
    [ngModel]="value"
    (ngModelChange)="onValueChange($event)" />
  <label for="status">
    <div
      class="status-switch"
      [attr.data-unchecked]="value ? rightOption : leftOption"
      [attr.data-checked]="value ? leftOption : rightOption"></div>
  </label>
</div>
