export const k6Meta = () => {
  const k6Properties = window.k6;
  return {
    k6: Object.assign({
      // we only add the k6 meta if Faro is running inside a k6 environment, so this is always true
      isK6Browser: true
    }, (k6Properties === null || k6Properties === void 0 ? void 0 : k6Properties.testRunId) && {
      testRunId: k6Properties === null || k6Properties === void 0 ? void 0 : k6Properties.testRunId
    })
  };
};
