import { dateNow, faro, genShortID } from '@grafana/faro-core';
import { isLocalStorageAvailable, isSessionStorageAvailable } from '../../../utils';
import { isSampled } from './sampling';
import { SESSION_EXPIRATION_TIME, SESSION_INACTIVITY_TIME } from './sessionConstants';
export function createUserSessionObject({
  sessionId,
  started,
  lastActivity,
  isSampled = true
} = {}) {
  var _a, _b;
  const now = dateNow();
  const generateSessionId = (_b = (_a = faro.config) === null || _a === void 0 ? void 0 : _a.sessionTracking) === null || _b === void 0 ? void 0 : _b.generateSessionId;
  if (sessionId == null) {
    sessionId = typeof generateSessionId === 'function' ? generateSessionId() : genShortID();
  }
  return {
    sessionId,
    lastActivity: lastActivity !== null && lastActivity !== void 0 ? lastActivity : now,
    started: started !== null && started !== void 0 ? started : now,
    isSampled: isSampled
  };
}
export function isUserSessionValid(session) {
  if (session == null) {
    return false;
  }
  const now = dateNow();
  const lifetimeValid = now - session.started < SESSION_EXPIRATION_TIME;
  if (!lifetimeValid) {
    return false;
  }
  const inactivityPeriodValid = now - session.lastActivity < SESSION_INACTIVITY_TIME;
  return inactivityPeriodValid;
}
export function getUserSessionUpdater({
  fetchUserSession,
  storeUserSession
}) {
  return function updateSession({
    forceSessionExtend
  } = {
    forceSessionExtend: false
  }) {
    var _a, _b, _c;
    if (!fetchUserSession || !storeUserSession) {
      return;
    }
    const sessionTrackingConfig = faro.config.sessionTracking;
    const isPersistentSessions = sessionTrackingConfig === null || sessionTrackingConfig === void 0 ? void 0 : sessionTrackingConfig.persistent;
    if (isPersistentSessions && !isLocalStorageAvailable || !isPersistentSessions && !isSessionStorageAvailable) {
      return;
    }
    const sessionFromStorage = fetchUserSession();
    if (forceSessionExtend === false && isUserSessionValid(sessionFromStorage)) {
      storeUserSession(Object.assign(Object.assign({}, sessionFromStorage), {
        lastActivity: dateNow()
      }));
    } else {
      let newSession = addSessionMetadataToNextSession(createUserSessionObject({
        isSampled: isSampled()
      }), sessionFromStorage);
      storeUserSession(newSession);
      (_a = faro.api) === null || _a === void 0 ? void 0 : _a.setSession(newSession.sessionMeta);
      (_b = sessionTrackingConfig === null || sessionTrackingConfig === void 0 ? void 0 : sessionTrackingConfig.onSessionChange) === null || _b === void 0 ? void 0 : _b.call(sessionTrackingConfig, (_c = sessionFromStorage === null || sessionFromStorage === void 0 ? void 0 : sessionFromStorage.sessionMeta) !== null && _c !== void 0 ? _c : null, newSession.sessionMeta);
    }
  };
}
export function addSessionMetadataToNextSession(newSession, previousSession) {
  var _a, _b, _c, _d;
  const sessionWithMeta = Object.assign(Object.assign({}, newSession), {
    sessionMeta: {
      id: newSession.sessionId,
      attributes: Object.assign(Object.assign(Object.assign(Object.assign({}, (_b = (_a = faro.config.sessionTracking) === null || _a === void 0 ? void 0 : _a.session) === null || _b === void 0 ? void 0 : _b.attributes), (_d = (_c = faro.metas.value.session) === null || _c === void 0 ? void 0 : _c.attributes) !== null && _d !== void 0 ? _d : {}), previousSession != null ? {
        previousSession: previousSession.sessionId
      } : {}), {
        isSampled: newSession.isSampled.toString()
      })
    }
  });
  return sessionWithMeta;
}
