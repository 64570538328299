import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class RemoveNullParamsInterceptor implements HttpInterceptor {
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'GET') {
      request = this.cleanParams(request);
      return next.handle(request);
    } else if (request.method === 'POST') {
      let body: any = request.body;
      for (let key in body) {
        if (body[key] === null) {
          delete body[key];
        }
      }
      request = request.clone(body);
      return next.handle(request);
    }
    return next.handle(request);
  }

  cleanParams(request: HttpRequest<any>): HttpRequest<any> {
    let params: HttpParams = request.params;
    request.params.keys().map((param) => {
      const value = request.params.get(param);
      if (
        value === undefined ||
        value === null ||
        value === '' ||
        value === 'undefined' ||
        value === 'null'
      ) {
        params = params.delete(param);
      }
    });
    return request.clone({
      params,
    });
  }
}
