var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { FaroXhrInstrumentation } from './faroXhrInstrumentation';
import { fetchCustomAttributeFunctionWithDefaults, xhrCustomAttributeFunctionWithDefaults } from './instrumentationUtils';
export function getDefaultOTELInstrumentations(options = {}) {
  const {
      fetchInstrumentationOptions,
      xhrInstrumentationOptions
    } = options,
    sharedOptions = __rest(options, ["fetchInstrumentationOptions", "xhrInstrumentationOptions"]);
  const fetchOpts = createFetchInstrumentationOptions(fetchInstrumentationOptions, sharedOptions);
  const xhrOpts = createXhrInstrumentationOptions(xhrInstrumentationOptions, sharedOptions);
  return [new FetchInstrumentation(fetchOpts), new FaroXhrInstrumentation(xhrOpts)];
}
function createFetchInstrumentationOptions(fetchInstrumentationOptions, sharedOptions) {
  return Object.assign(Object.assign(Object.assign(Object.assign({}, sharedOptions), {
    ignoreNetworkEvents: true
  }), fetchInstrumentationOptions), {
    // always keep this function
    applyCustomAttributesOnSpan: fetchCustomAttributeFunctionWithDefaults(fetchInstrumentationOptions === null || fetchInstrumentationOptions === void 0 ? void 0 : fetchInstrumentationOptions.applyCustomAttributesOnSpan)
  });
}
function createXhrInstrumentationOptions(xhrInstrumentationOptions, sharedOptions) {
  return Object.assign(Object.assign(Object.assign(Object.assign({}, sharedOptions), {
    ignoreNetworkEvents: true
  }), xhrInstrumentationOptions), {
    // always keep this function
    applyCustomAttributesOnSpan: xhrCustomAttributeFunctionWithDefaults(xhrInstrumentationOptions === null || xhrInstrumentationOptions === void 0 ? void 0 : xhrInstrumentationOptions.applyCustomAttributesOnSpan)
  });
}
