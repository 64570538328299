import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogModule as MatDialogModule,
} from '@angular/material/legacy-dialog';
import { FoIconComponent } from '@ui/components/fo-icon/fo-icon.component';
import { Auth2Service } from 'src/app/services/auth2.service';
import { SECONDS_UNTIL_WARNING_LOGOUT } from '../../../constants/logout-times';

@Component({
  standalone: true,
  selector: 'app-admin-log-out-timer',
  templateUrl: './admin-log-out-countdown.component.html',
  styleUrls: ['./admin-log-out-countdown.component.scss'],
  imports: [
    CommonModule,
    // Material
    MatButtonModule,
    MatDialogModule,
    // Commons
    FoIconComponent,
  ],
})
export class AdminLogOutCountdownComponent implements OnInit, OnDestroy {
  secondsLeft: number;

  private timerInterval: any;

  constructor(
    private readonly authService: Auth2Service,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.startTimer();
  }

  onContinue() {
    clearInterval(this.timerInterval);
    this.dialog.closeAll();
  }

  private logOut() {
    clearInterval(this.timerInterval);
    this.dialog.closeAll();
    this.authService.logout$.next();
  }

  private startTimer() {
    this.secondsLeft = SECONDS_UNTIL_WARNING_LOGOUT;
    this.timerInterval = setInterval(() => {
      this.secondsLeft--;
      if (this.secondsLeft <= 0) {
        this.logOut();
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
    clearInterval(this.timerInterval);
  }
}
