import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
export class FaroXhrInstrumentation extends XMLHttpRequestInstrumentation {
  constructor(config = {}) {
    super(config);
    const self = this;
    this.parentCreateSpan = self._createSpan.bind(this);
  }
  // Patching the parent's private method to handle url type string or URL
  _patchOpen() {
    return original => {
      const plugin = this;
      return function patchOpen(...args) {
        const method = args[0];
        let url = args[1];
        if (isInstanceOfURL(url)) {
          url = url.href;
        }
        plugin.parentCreateSpan(this, url, method);
        return original.apply(this, args);
      };
    };
  }
}
function isInstanceOfURL(item) {
  return item instanceof URL;
}
