import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Alert, AlertService, AlertType } from 'src/app/services/alert.service';

@Component({
  selector: 'fo-toast-notification',
  templateUrl: './fo-toast-notification.component.html',
  styleUrls: ['./fo-toast-notification.component.scss'],
})
export class FoToastNotificationComponent implements OnInit, OnDestroy {
  @Input() public id: string = 'default-alert';
  @Input() public fade: boolean = true;

  public alerts: Alert[] = [];
  public alertSubscription: Subscription;
  public routeSubscription: Subscription;

  constructor(
    private readonly router: Router,
    private readonly alertService: AlertService,
  ) {}

  public ngOnInit(): void {
    this.alertSubscription = this.alertService.onAlert(this.id).subscribe((alert) => {
      // clear alerts when an empty alert is received
      if (!alert.message) {
        // filter out alerts without 'keepAfterRouteChange' flag
        this.alerts = this.alerts.filter((i) => i.keepAfterRouteChange);

        // remove 'keepAfterRouteChange' flag on the rest
        this.alerts.forEach((i) => i.keepAfterRouteChange);
        return;
      }

      // add alert to array
      this.alerts.push(alert);

      // auto close alert if required
      if (alert.autoClose) {
        setTimeout(() => this.removeAlert(alert), 3000);
      }
    });

    // clear alerts on location change
    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.alertService.clear(this.id);
      }
    });
  }

  public ngOnDestroy(): void {
    this.alertSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  public cssClass(alert: Alert): string {
    if (!alert) return;
    switch (alert.type) {
      case AlertType.Error:
        return 'danger';
      case AlertType.Info:
        return 'info';
      case AlertType.Success:
        return 'success';
      case AlertType.Warning:
        return 'warning';
    }
  }

  public removeAlert(alert: Alert): void {
    // check if already removed to prevent error on auto close
    if (!this.alerts.includes(alert)) return;

    if (this.fade) {
      // fade out alert
      this.alerts.find((i) => i === alert).fade = true;

      // remove alert after faded out
      setTimeout(() => {
        this.alerts = this.alerts.filter((i) => i !== alert);
      }, 250);
    } else {
      // remove alert
      this.alerts = this.alerts.filter((i) => i !== alert);
    }
  }
}
