import { SpanStatusCode } from '@opentelemetry/api';
/**
 * Adds HTTP status code to every span.
 *
 * The fetch instrumentation does not always set the span status to error as defined by the spec.
 * To work around that issue we manually set the span status.
 *
 * Issue: https://github.com/open-telemetry/opentelemetry-js/issues/3564
 * Spec: https://github.com/open-telemetry/opentelemetry-specification/blob/v1.20.0/specification/trace/semantic_conventions/http.md#status
 */
export function setSpanStatusOnFetchError(span, _request, result) {
  const httpStatusCode = result instanceof Error ? 0 : result.status;
  setSpanStatus(span, httpStatusCode);
}
export function setSpanStatusOnXMLHttpRequestError(span, xhr) {
  setSpanStatus(span, xhr.status);
}
function setSpanStatus(span, httpStatusCode) {
  if (httpStatusCode == null) {
    return;
  }
  const isError = httpStatusCode === 0;
  const isClientOrServerError = httpStatusCode >= 400 && httpStatusCode < 600;
  if (isError || isClientOrServerError) {
    span.setStatus({
      code: SpanStatusCode.ERROR
    });
  }
}
export function fetchCustomAttributeFunctionWithDefaults(callback) {
  return (span, request, result) => {
    setSpanStatusOnFetchError(span, request, result);
    callback === null || callback === void 0 ? void 0 : callback(span, request, result);
  };
}
export function xhrCustomAttributeFunctionWithDefaults(callback) {
  return (span, xhr) => {
    setSpanStatusOnXMLHttpRequestError(span, xhr);
    callback === null || callback === void 0 ? void 0 : callback(span, xhr);
  };
}
