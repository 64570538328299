import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Auth2Service } from '../services/auth2.service';

@Injectable()
export class Token2Interceptor implements HttpInterceptor {
  /**
   * List of api calls that should be ignored
   */
  private readonly WHITE_LIST: string[] = [`${environment.apiBaseUrl}/identity/usertoken`];

  /**
   * Var which indicates if there is any refreshing process active
   */
  private isRefreshingToken: boolean = false;

  /**
   * Observable which indicates when the token has been refreshed
   */
  private tokenRefreshed$: Subject<boolean> = new Subject();

  constructor(private readonly authService: Auth2Service) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Url is in white list or is different to api base
    if (this.WHITE_LIST.includes(request.url) || !request.url.includes(environment.apiBaseUrl)) {
      return next.handle(request);
    }

    return next.handle(this.addTokenHeader(request)).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.isRefreshingToken
            ? this.tokenRefreshed$.pipe(switchMap(() => this.retryRequest(request, next)))
            : this.authService.isSessionExpired()
              ? this.handleRefresh(request, next)
              : this.retryRequest(request, next);
        }
        return throwError(error);
      }),
    );
  }

  /**
   * Request a new token using refreshSession function and then, retry request
   * @param request
   * @param next
   * @returns new request or throw error
   */
  handleRefresh(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.isRefreshingToken = true;
    return this.authService.refreshSession().pipe(
      switchMap(() => this.retryRequest(request, next)),
      catchError(() => {
        this.authService.logout$.next();
        return of(null);
      }),
      finalize(() => {
        this.isRefreshingToken = false;
        this.tokenRefreshed$.next();
      }),
    );
  }

  /**
   * Get request with updated token and pipe options
   * @param request
   * @param next
   * @returns new request with updated token and predefined actions
   */
  retryRequest(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addTokenHeader(request)).pipe(
      takeUntil(this.authService.logout$),
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.authService.logout$.next();
          return of(null);
        }
        return throwError(error);
      }),
    );
  }

  /**
   * Add current token to request
   * @param request reques to clone
   * @returns request cloned with the Bearer token
   */
  private addTokenHeader(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.authService.accessToken || '';
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
