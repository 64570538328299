import { genShortID, unknownString } from '@grafana/faro-core';
import { getItem, setItem, webStorageType } from '../../utils';
import { NAVIGATION_ID_STORAGE_KEY } from '../instrumentationConstants';
import { NAVIGATION_ENTRY } from './performanceConstants';
import { createFaroNavigationTiming, entryUrlIsIgnored, getSpanContextFromServerTiming } from './performanceUtils';
export function getNavigationTimings(pushEvent, ignoredUrls) {
  let faroNavigationEntryResolve;
  const faroNavigationEntryPromise = new Promise(resolve => {
    faroNavigationEntryResolve = resolve;
  });
  const observer = new PerformanceObserver(observedEntries => {
    var _a;
    const [navigationEntryRaw] = observedEntries.getEntries();
    if (navigationEntryRaw == null || entryUrlIsIgnored(ignoredUrls, navigationEntryRaw.name)) {
      return;
    }
    const navEntryJson = navigationEntryRaw.toJSON();
    let spanContext = getSpanContextFromServerTiming(navEntryJson === null || navEntryJson === void 0 ? void 0 : navEntryJson.serverTiming);
    const faroPreviousNavigationId = (_a = getItem(NAVIGATION_ID_STORAGE_KEY, webStorageType.session)) !== null && _a !== void 0 ? _a : unknownString;
    const faroNavigationEntry = Object.assign(Object.assign({}, createFaroNavigationTiming(navEntryJson)), {
      faroNavigationId: genShortID(),
      faroPreviousNavigationId
    });
    setItem(NAVIGATION_ID_STORAGE_KEY, faroNavigationEntry.faroNavigationId, webStorageType.session);
    pushEvent('faro.performance.navigation', faroNavigationEntry, undefined, {
      spanContext,
      timestampOverwriteMs: performance.timeOrigin + navEntryJson.startTime
    });
    faroNavigationEntryResolve(faroNavigationEntry);
  });
  observer.observe({
    type: NAVIGATION_ENTRY,
    buffered: true
  });
  return faroNavigationEntryPromise;
}
