import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, iif, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Auth2Service } from '../services/auth2.service';

@Injectable({
  providedIn: 'root',
})
export class Auth2Guard {
  constructor(private authservice: Auth2Service) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canAccessToUrl(state.url);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canAccessToUrl(state.url);
  }

  /**
   * Verifies if user sessionis expired and logout the user on that case
   * @param returnUrl returnUrl if there is any
   * @returns whether the user is authenticated
   */
  private isAuthenticated(): Observable<boolean> {
    return of(this.authservice.isSessionPresent());
  }

  /**
   * Verify if user is authenticated and can access to a specific url
   * @param url the url requested
   * @returns whether the user can access
   */
  private canAccessToUrl(url: string) {
    return this.isAuthenticated().pipe(
      switchMap((isAuthenticated) =>
        iif(() => isAuthenticated, this.authservice.canAccessToUrl(url), of(false)),
      ),
      tap((hasAccess) => !hasAccess && this.authservice.logout$.next(url)),
    );
  }
}
