import { unknownSymbolString } from './const';
export function buildStackFrame(filename, func, lineno, colno) {
  const stackFrame = {
    filename: filename || document.location.href,
    function: func || unknownSymbolString
  };
  if (lineno !== undefined) {
    stackFrame.lineno = lineno;
  }
  if (colno !== undefined) {
    stackFrame.colno = colno;
  }
  return stackFrame;
}
