import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { FoIconComponent } from '@ui/components/fo-icon/fo-icon.component';
import { BreadcrumbComponent } from '@ui/layout/breadcrumb/breadcrumb.component';
import { HeaderComponent } from '@ui/layout/header/header.component';
import { MainMenuComponent } from '@ui/layout/main-menu/main-menu.component';
import { filter, pairwise, startWith } from 'rxjs/operators';
import { setBreadcrumbParts } from 'src/app/states/breadcrumb/actions/breadcrumb.actions';
import { BreadcrumbState } from 'src/app/states/breadcrumb/breadcrumb.state';
import { selectBreadcrumbTitle } from 'src/app/states/breadcrumb/selector/breadcrumb.selector';

@Component({
  selector: 'app-default-template',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    // Material
    MatButtonModule,
    MatSidenavModule,
    // Commons
    FoIconComponent,
    // Components
    BreadcrumbComponent,
    HeaderComponent,
    MainMenuComponent,
  ],
  templateUrl: './default-template.component.html',
  styleUrls: ['./default-template.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DefaultTemplateComponent {
  /**
   * Sidenav width on expanded and collapsed states
   */
  readonly SIDENAV_WIDTH = {
    expanded: '240px',
    collapsed: '90px',
  };

  /**
   * Sidenav expandible indicator
   */
  isExpanded: boolean = true;

  constructor(
    private router: Router,
    private store: Store<BreadcrumbState>,
    private readonly titleService: Title,
  ) {
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        startWith({} as NavigationEnd),
        pairwise(),
      )
      .subscribe((events: NavigationEnd[]) => {
        if (events[0].urlAfterRedirects !== events[1].urlAfterRedirects) {
          this.store.dispatch(setBreadcrumbParts({ event: events[1] }));
        }
      });

    this.store
      .select(selectBreadcrumbTitle)
      .subscribe((title) => this.titleService.setTitle(title));
  }
}
