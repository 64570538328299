import { allLogLevels, BaseInstrumentation, isArray, isObject, LogLevel, VERSION } from '@grafana/faro-core';
export class ConsoleInstrumentation extends BaseInstrumentation {
  constructor(options = {}) {
    super();
    this.options = options;
    this.name = '@grafana/faro-web-sdk:instrumentation-console';
    this.version = VERSION;
  }
  initialize() {
    this.logDebug('Initializing\n', this.options);
    allLogLevels.filter(level => {
      var _a;
      return !((_a = this.options.disabledLevels) !== null && _a !== void 0 ? _a : ConsoleInstrumentation.defaultDisabledLevels).includes(level);
    }).forEach(level => {
      /* eslint-disable-next-line no-console */
      console[level] = (...args) => {
        try {
          if (level === LogLevel.ERROR) {
            this.api.pushError(new Error(args.map(arg => isObject(arg) || isArray(arg) ? JSON.stringify(arg) : arg).join(' ')));
          } else {
            this.api.pushLog(args, {
              level
            });
          }
        } catch (err) {
          this.logError(err);
        } finally {
          this.unpatchedConsole[level](...args);
        }
      };
    });
  }
}
ConsoleInstrumentation.defaultDisabledLevels = [LogLevel.DEBUG, LogLevel.TRACE, LogLevel.LOG];
