import { ExportResultCode } from '@opentelemetry/core';
import { createExportTraceServiceRequest } from '@opentelemetry/otlp-transformer';
import { sendFaroEvents } from './faroTraceExporter.utils';
export class FaroTraceExporter {
  constructor(config) {
    this.config = config;
  }
  export(spans, resultCallback) {
    const traceEvent = createExportTraceServiceRequest(spans, {
      useHex: true,
      useLongBits: false
    });
    this.config.api.pushTraces(traceEvent);
    sendFaroEvents(traceEvent.resourceSpans);
    resultCallback({
      code: ExportResultCode.SUCCESS
    });
  }
  shutdown() {
    return Promise.resolve(undefined);
  }
}
