<header mat-dialog-title class="text-center">
	<h1>Your session will end</h1>
	<button class="button-close" mat-icon-button aria-label="close dialog" mat-dialog-close (click)="onContinue()">
    <fo-icon class="button-close" name="close"></fo-icon>
  </button>
</header>
<mat-dialog-content class="text-center">
	<fo-icon class="icon" name="warning"></fo-icon>
	<p>Your session will end in
		<span class="text-blue">{{secondsLeft * 1000 | date: 'mm:ss'}}</span>
		seconds due to inactivity.</p>
	<p>As a security precaution, if there is no additional activity in your online session,
		the session will end and you will be brought back to the login page. Any unsaved work may be lost</p>
	<p>If you are still working in your online session, please press OK to continue</p>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-center">
	<button
		mat-raised-button
		color="primary"
		cdkFocusInitial
		mat-dialog-close
		(click)="onContinue()"
	> Ok
	</button>
</mat-dialog-actions>
