import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-slide-toggle',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SlideToggleComponent {
  /**
   * Option on left, false value
   */
  @Input()
  leftOption: string;

  /**
   * Option on right, true value
   */
  @Input()
  rightOption: string;

  /**
   * Element value
   */
  @Input()
  value: boolean;

  /**
   * On value changes
   */
  @Output()
  valueChange: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  /**
   * Emit an event on value change
   */
  onValueChange(value: boolean) {
    this.value = value;
    this.valueChange.next(value);
  }
}
