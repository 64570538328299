import { BaseInstrumentation, VERSION } from '@grafana/faro-core';
import { WebVitalsBasic } from './webVitalsBasic';
import { WebVitalsWithAttribution } from './webVitalsWithAttribution';
export class WebVitalsInstrumentation extends BaseInstrumentation {
  constructor() {
    super(...arguments);
    this.name = '@grafana/faro-web-sdk:instrumentation-web-vitals';
    this.version = VERSION;
  }
  initialize() {
    this.logDebug('Initializing');
    const webVitals = this.intializeWebVitalsInstrumentation();
    webVitals.initialize();
  }
  intializeWebVitalsInstrumentation() {
    if (this.config.trackWebVitalsAttribution) {
      return new WebVitalsWithAttribution(this.api.pushMeasurement);
    }
    return new WebVitalsBasic(this.api.pushMeasurement);
  }
}
