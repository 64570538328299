import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BreadcrumbState } from '../breadcrumb.state';

/**
 * Get breadcrumb
 */
export const selectBreadcrumbState = createFeatureSelector<BreadcrumbState>('breadcrumb');

/**
 * Get page title given breadcrumb
 */
export const selectBreadcrumbTitle = createSelector(
  selectBreadcrumbState,
  ({ parts, details }): string =>
    [...parts.map((part) => part.name), details?.description].filter((part) => !!part).join(' - '),
);
